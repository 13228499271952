<template>
  <section class="flex flex-col pb-48">

    <!--    1st screen -->
    <div class="container flex mx-auto min-h-screen mb-24">
      <div class="w-full my-auto">
        <div class="flex">
          <div class="md:w-2/3 px-4 my-32">
            <h1 class="text-4xl sm:text-6xl font-black">Munkaerő-kölcsönzés</h1>
            <p class="mt-8">Bízza ránk a munkát és élvezze az eredményt megbízható szakemberekkel</p>
            <div class="mt-16">
              <button-element @click="$emit('toggleModal')">Legyél a partnerünk</button-element>
            </div>
          </div>

          <div class="hidden md:flex p-4 w-1/3">
            <div class="m-auto w-full">
              <img alt="bounce" src="@/assets/bg-abs.svg">
            </div>
          </div>
        </div>

        <div class="container mx-auto flex flex-col md:flex-row">
          <div class="md:w-1/3 p-4">
            <h3 class="font-bold mb-4">Fenntartható munkaerő-piaci beilleszkedés magyar és külföldi munkavállalók
              számára</h3>
            <p>
              Magyar és külföldi munkavállalók munkaerő piacba való - minden fél számára -
              fenntartható
              beillesztésével foglalkozunk.
            </p>
          </div>
          <div class="md:w-1/3 p-4">
            <h3 class="font-bold mb-4">Teljes körű munkavállalói foglalkoztatás: az adminisztrációtól az
              engedélyeztetésig</h3>
            <p>
              Átvállaljuk a munkavállalók foglalkoztatásával összefüggésben felmerülő szinte összes
              lépést,
              kezdve az adminisztrációtól, az engedélyeztetésen át mindent, ami partnereink sikeres szervezetépítéshez
              szükséges.
            </p>
          </div>
          <div class="md:w-1/3 p-4">
            <h3 class="font-bold mb-4">Az ügyfelek érdekei és a munkavállalói elégedettség figyelembevételével
              kezeljük a beilleszkedést</h3>
            <p>
              A munkavállalók beilleszkedését a megrendelő igényeinek megfelelően kezeljük, szem
              előtt
              tartva
              a munkavállaló érdekeit is.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--    2nd screen -->
    <div class="container my-24 mx-auto py-24 px-4">
      <heading-component class="mb-8">Tevékenységeink</heading-component>
      <services-list/>
    </div>

    <!--    3rd screen -->
    <company-block/>

    <!--    4th screen -->
    <partners-block @toggleModal="$emit('toggleModal')"/>

  </section>
</template>

<script setup>
import router from '@/router'
import setUTMs from '@/utils/setUTMs'
import HeadingComponent from '@/components/UI/HeadingComponent.vue'
import ServicesList from '@/components/ServicesList.vue'
import CompanyBlock from '@/components/CompanyBlock.vue'
import PartnersBlock from '@/components/PartnersBlock.vue'
import ButtonElement from '@/components/UI/ButtonElement.vue'

setUTMs(router.currentRoute.value.query)
</script>
