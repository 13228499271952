<template>
  <button v-if="bt === 'send'"
          class="py-4 px-8 bg-blue-500 hover:bg-blue-600 text-white hover:text-gray-100 font-bold rounded-br-xl rounded-tl-xl">
    <slot/>
  </button>

  <button v-else-if="bt === 'processing'"
          class="py-4 px-8 bg-blue-400 text-white font-bold rounded-br-xl rounded-tl-xl cursor-default"
          type="submit">
      <span class="flex justify-center">
        <span class="animate-spin border-b w-4 h-4 my-auto mr-4 rounded-xl"/>
        <slot/>
      </span>
  </button>

</template>

<script>
export default {
  props: {
    bt: {
      type: String,
      default: 'send'
    }
  }
}
</script>
