<template>
  <header class="fixed left-0 right-0 z-30 bg-white/60 backdrop-blur-md">
    <div class="container mx-auto p-4 flex justify-between items-center">

      <router-link class="flex" to="/#">
        <logo-component class="w-48 sm:w-60 my-auto"/>
      </router-link>

      <!--    desctop menu items -->
      <nav class="hidden lg:flex justify-between w-2/5">
        <router-link v-for="route in routes"
                     :key="route"
                     :to="route.path"
                     active-class="text-blue-500 hover:text-blue-500 cursor-default"
                     class="p-4 font-black">
          {{ route.title }}
        </router-link>
      </nav>

      <button-element class="hidden lg:block" @click="$emit('toggleModal')">Legyél a partnerünk</button-element>

      <!--    burger menu button -->
      <div class="lg:hidden w-8 h-8">
        <button v-if="!menuOpen"
                @click="menuOpen=!menuOpen">
          <svg class="block w-full fill-blue-500" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
          </svg>
        </button>

        <button v-else
                @click="menuOpen=!menuOpen">
          <svg class="block w-full fill-blue-200" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
          </svg>
        </button>
      </div>

    </div>

    <!--    burger menu items -->
    <div v-show="menuOpen"
         class="flex lg:hidden">
      <nav class="container mx-auto flex flex-col">

        <router-link v-for="route in routes"
                     :key="route"
                     :to="route.path"
                     active-class="text-blue-500 hover:text-blue-500 cursor-default"
                     class="p-4 font-black"
                     @click="menuOpen=!menuOpen">
          {{ route.title }}
        </router-link>

        <button-element class="mt-16 mb-16 mx-4"
                        @click="$emit('toggleModal')">
          Legyél a partnerünk
        </button-element>

      </nav>
    </div>
  </header>
</template>

<script setup>
import LogoComponent from '@/components/UI/LogoComponent.vue'
import ButtonElement from '@/components/UI/ButtonElement.vue'
import { ref } from 'vue'

const routes = [
  {
    path: '/',
    title: 'Home'
  },
  {
    path: '/services',
    title: 'Tevékenységeink'
  },
  {
    path: '/contacts',
    title: 'Kapcsolat'
  }
]

const menuOpen = ref(false)
</script>
