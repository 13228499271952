<template>
  <div class="flex flex-wrap justify-between">
    <div v-for="service in services"
         :key="service"
         class="relative flex w-full lg:w-[33%] h-80 mb-4 text-center text-white">
      <router-link :to="`/services/${service.path}`">
        <div
          class="absolute inset-0 w-full h-full bg-black/60 hover:bg-black/80 z-10 rounded-br-2xl rounded-tl-2xl flex duration-400">
          <div class="m-auto p-8">
            <h3 class="text-2xl font-bold mb-4">{{ service.title }}</h3>
            <p>{{ service.description }}</p>
          </div>
        </div>

        <img :alt="service.title"
             :src="service.image"
             class="absolute inset-0 w-full h-full object-cover z-0 rounded-br-2xl rounded-tl-2xl">
      </router-link>
    </div>
  </div>
</template>

<script>
import { services } from '@/store'

export default {
  name: 'ServicesList',

  data () {
    return {
      services
    }
  }
}
</script>
