<template>
  <div ref="modal"
       class="z-50 fixed inset-0 bg-white/90 overflow-auto"
       tabindex="0"
       @click="close"
       @keyup.esc="close">
    <div class="flex min-h-full p-2">
      <div class="bg-white m-auto w-fit p-4 sm:p-8 lg:p-16 border shadow rounded-tl-3xl rounded-br-3xl"
           @click.stop="">
        <div class="flex flex-col my-4">
          <div class="cursor-pointer w-fit" @click="close">
            <div class="w-12">
              <arrow-to-left/>
            </div>
          </div>
          <div class="pt-8">
            <!--            Modal content-->
            <slot/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowToLeft from '@/components/UI/ArrowToLeft.vue'

export default {
  components: { ArrowToLeft },
  props: {
    closePopup: Object
  },
  setup (props, { emit }) {
    const close = () => {
      emit('close')
    }
    return { close }
  },
  mounted () {
    this.$refs.modal.focus()
  }
}
</script>
