<template>
  <section class="flex flex-col pb-48">

    <!--    1st screen -->
    <div class="min-h-screen text-white">
      <div
        class="absolute inset-0 flex flex-col px-4 w-full h-full bg-black/60 z-10">

        <div class="container m-auto p-4">
          <arrow-to-left class="mb-24"
                         color="white"
                         @click="this.$router.go(-1)"/>

          <h1 class="mb-8 text-2xl sm:text-5xl font-black">{{ content.h1 }}</h1>
          <h2 class="mb-16">{{ content.subtitle }}</h2>
          <button-element @click="$emit('toggleModal')">
            Legyél a partnerünk
          </button-element>
        </div>
      </div>

      <div class="absolute inset-0 z-0 bg-black">
        <img :alt="content.h1"
             :src="content.coverImage"
             class="object-cover w-full h-full">
      </div>
    </div>

    <!--    2nd screen -->
    <div class="container flex flex-col mx-auto py-24 px-4">
      <div v-for="element in content.body" :key=element>
        <h2 v-if="element.tag === 'h2'"
            class="text-center text-2xl mb-8 p-8 bg-blue-100 rounded-tl-xl rounded-br-xl">
          {{ element.text }}
        </h2>
        <p v-else-if="element.tag === 'p'"
           class="mx-auto p-8 xl:w-1/2">
          {{ element.text }}
        </p>
        <div v-else-if="element.tag === 'ul'"
             class="mx-auto p-8 xl:w-1/2">
          <h3 v-if="element.header" class="font-bold">{{ element.header }}</h3>
          <ul>
            <li v-for="li in element.li" :key="li">{{ li }}</li>
          </ul>
        </div>
      </div>
    </div>

    <!--    3rd screen -->
    <company-block/>

    <!--    4th screen -->
    <partners-block @toggleModal="$emit('toggleModal')"/>

  </section>
</template>

<script>
import CompanyBlock from '@/components/CompanyBlock.vue'
import PartnersBlock from '@/components/PartnersBlock.vue'
import ArrowToLeft from '@/components/UI/ArrowToLeft.vue'
import ButtonElement from '@/components/UI/ButtonElement.vue'

export default {
  name: '__ServiceLayout',
  components: {
    ButtonElement,
    ArrowToLeft,
    PartnersBlock,
    CompanyBlock
  },
  props: {
    content: Object
  }
}
</script>
