<template>
  <section class="flex flex-col text-lg pb-48">
    <div class="container my-24 mx-auto py-24 px-4">
      <heading-component class="mb-8">Tevékenységeink</heading-component>
      <services-list/>
    </div>
  </section>
</template>

<script setup>
import setUTMs from '@/utils/setUTMs'
import router from '@/router'
import HeadingComponent from '@/components/UI/HeadingComponent.vue'
import ServicesList from '@/components/ServicesList.vue'

setUTMs(router.currentRoute.value.query)
</script>
