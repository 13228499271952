<template>
  <form ref="leadform" @submit="submitForm" @submit.prevent>
    <input
      v-model="formState.company_name"
      class="bg-gray-100 block w-full p-4 rounded-tl-md rounded-br-md my-2 focus:outline-none"
      placeholder="Your Company Name"
      required
      type="text"/>
    <input
      v-model="formState.fullname"
      class="bg-gray-100 block w-full p-4 rounded-tl-md rounded-br-md my-2 focus:outline-none"
      placeholder="Your Fullname"
      required
      type="text"/>
    <input
      v-model="formState.email"
      class="bg-gray-100 block p-4 w-full rounded-tl-md rounded-br-md my-2 focus:outline-none"
      placeholder="your@email.com *"
      required
      type="email"/>
    <input
      v-model="formState.phone"
      class="bg-gray-100 block p-4 w-full rounded-tl-md rounded-br-md focus:outline-none"
      placeholder="+36 00 000 00 00 *"
      required
      type="text"/>
    <!--    {error.phone && <p class="text-center text-red-500 text-xs mt-2">{error.phone}</p>}-->
    <textarea
      v-model="formState.comment"
      class="bg-gray-100 block p-4 w-full rounded-tl-md rounded-br-md my-2 focus:outline-none"
      placeholder="Your comment"
      type="text"></textarea>

    <div class="flex">
      <input
        v-model="formState.privacy"
        class="inline-block rounded-tl-md rounded-br-md my-2 focus:outline-none"
        required
        type="checkbox"/>
      <label class="inline-block ml-2 my-auto text-xs">
        I'm agree with policy and privacy
      </label>
    </div>

    <div v-if="errorMessage"
         class="text-center bg-red-400 text-white font-bold p-8 mb-4 rounded-tl-xl rounded-br-xl">
      {{ errorMessage }}
    </div>

    <div v-else-if="submittedSuccess"
         class="text-center bg-green-400 text-white font-bold p-8 mb-4 rounded-tl-xl rounded-br-xl">
      <p>

        Köszönjük, hogy kitöltötted az űrlapot! Az általad megadott információk alapján hamarosan felveszünk veled
        kapcsolatot. Ha bármilyen kérdésed van addig is, ne habozz felvenni velünk a kapcsolatot az alábbi
        elérhetőségeken:
      </p>
      <br>
      <p>
        E-mail: info@ravienjob.hu
      </p>
      <br>
      <p>
        Köszönjük, hogy érdeklődsz Ravien Job szolgáltatásai iránt!
      </p>
      <br>
      <p>
        Üdvözlettel,
        Ravien Job csapata
      </p>
    </div>

    <button-element v-if="submitting"
                    :bt="'processing'"
                    class="mt-8 w-full">
      Processing
    </button-element>

    <button-element v-else
                    :bt="'send'"
                    class="mt-8 w-full"
                    @submit="submitForm">
      Send
    </button-element>

  </form>
</template>

<script>
import ButtonElement from '@/components/UI/ButtonElement.vue'
import getUTMs from '@/utils/getUTMs'

export default {
  components: { ButtonElement },
  data () {
    const initialState = {
      company_name: '',
      fullname: '',
      phone: '',
      email: '',
      comment: '',
      privacy: false,
      from_page: this.$route.path,
      utms: getUTMs()
    }

    const formState = { ...initialState }
    const errorMessage = ''

    return {
      initialState,
      formState,
      errorMessage,
      submitting: false,
      submittedSuccess: false
    }
  },

  methods: {
    async submitForm () {
      this.submitting = true
      if (this.errorMessage) {
        this.errorMessage = ''
      }
      const JSONdata = JSON.stringify(this.formState)
      const endpoint = `${process.env.VUE_APP_API_URL}/lead/`

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSONdata
      }

      await fetch(endpoint, options).then(() => {
        this.submitting = false
        this.formState = { ...this.initialState }
        this.submittedSuccess = true
      }).catch(() => {
        this.submitting = false
        this.errorMessage = 'Something went wrong, try again'
      })
    }
  },

  watch: {
    formState: {
      handler (newState, oldState) {
        if (this.errorMessage) {
          this.errorMessage = ''
        }
      },
      deep: true
    }
  }
}
</script>
