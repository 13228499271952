const setUTMs = (query) => {
  if (query) {
    const utmSource = query.utm_source
    const utmMedium = query.utm_medium
    const utmCampaign = query.utm_campaign
    const utmTerm = query.utm_term
    const utmContent = query.utm_content

    const utms = {}

    if (utmSource) {
      utms.utm_source = utmSource
    }
    if (utmMedium) {
      utms.utm_medium = utmMedium
    }
    if (utmCampaign) {
      utms.utm_campaign = utmCampaign
    }
    if (utmTerm) {
      utms.utm_term = utmTerm
    }
    if (utmContent) {
      utms.utm_content = utmContent
    }

    if (Object.keys(utms).length > 0) {
      localStorage.setItem('utms', JSON.stringify(utms))
    }

    return utms
  }
}

export default setUTMs
