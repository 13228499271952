<template>
  <navigation-component @toggleModal="toggleModal"/>
  <router-view @toggleModal="toggleModal"/>
  <footer-component/>

  <modal-component v-if="modalActive" @close="toggleModal">
    <div>
      <heading-component>Legyél a partnerünk</heading-component>
      <p class="mt-4 mb-8">Bízza ránk a munkát és élvezze az eredményt megbízható szakemberekkel</p>
      <form-component @close="toggleModal"/>
    </div>
  </modal-component>
</template>

<script setup>
import { ref } from 'vue'
import NavigationComponent from '@/components/NavigationComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import ModalComponent from '@/components/UI/ModalComponent.vue'
import HeadingComponent from '@/components/UI/HeadingComponent.vue'
import FormComponent from '@/components/FormComponent.vue'

const modalActive = ref(false)

const toggleModal = () => {
  modalActive.value = !modalActive.value
}
</script>
