import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import addMetaTags from '@/utils/addMetaTags'
import ServicesView from '@/views/services/ServicesView.vue'
import PageNotFoundView from '@/views/PageNotFoundView.vue'
import ContactsView from '@/views/ContactsView.vue'
import { services } from '@/store'
import __ServiceLayout from '@/views/services/__ServiceLayout.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Labor bérbeadás – Ravien Job Munkaerőkölcsönző Kft.',
      metaTags: [
        {
          name: 'description',
          content: 'Teljes körű munkavállalói foglalkoztatás: az adminisztrációtól az engedélyeztetésig'
        },
        {
          property: 'og:description',
          content: 'Teljes körű munkavállalói foglalkoztatás: az adminisztrációtól az engedélyeztetésig'
        },
        {
          name: 'theme-color',
          content: '#3B82F6'
        }
      ]
    }
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView,
    meta: {
      title: 'Tevékenységeink – Ravien Job Munkaerőkölcsönző Kft.',
      metaTags: [
        {
          name: 'description',
          content: 'Teljes körű munkavállalói foglalkoztatás: az adminisztrációtól az engedélyeztetésig'
        },
        {
          property: 'og:description',
          content: 'Teljes körű munkavállalói foglalkoztatás: az adminisztrációtól az engedélyeztetésig'
        },
        {
          name: 'theme-color',
          content: '#3B82F6'
        }
      ]
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView,
    meta: {
      title: 'Tevékenységeink – Ravien Job Munkaerőkölcsönző Kft.',
      metaTags: [
        {
          name: 'description',
          content: 'Teljes körű munkavállalói foglalkoztatás: az adminisztrációtól az engedélyeztetésig'
        },
        {
          property: 'og:description',
          content: 'Teljes körű munkavállalói foglalkoztatás: az adminisztrációtól az engedélyeztetésig'
        },
        {
          name: 'theme-color',
          content: '#3B82F6'
        }
      ]
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFoundView
  }
]

services.forEach(service => {
  routes.push({
    path: `/services/${service.path}`,
    name: service.title,
    component: __ServiceLayout,
    props: {
      content: service.content
    },
    meta: {
      title: `${service.title} – Ravien Job Munkaerőkölcsönző Kft.`,
      metaTags: [
        {
          name: 'description',
          content: service.description
        },
        {
          property: 'og:description',
          content: service.description
        },
        {
          name: 'theme-color',
          content: '#3B82F6'
        }
      ]
    }
  })
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})

addMetaTags(router)

export default router
