import permitWebP from '@/assets/services/permit.webp'
import recruitmentWebP from '@/assets/services/recruitment.webp'
import outsorsingWebP from '@/assets/services/outsorsing.webp'

export const services = [
  {
    id: 1,
    title: 'Engedélyeztetés',
    description: 'Vegye igénybe bérmunka szolgáltatásainkat és koncentráljon az üzleti céljaira',
    path: 'permit',
    image: permitWebP,
    imageMin: permitWebP,
    content: {
      coverImage: permitWebP,
      h1: 'Engedélyeztetés',
      subtitle: 'Vegye igénybe bérmunka szolgáltatásainkat és koncentráljon az üzleti céljaira',
      body: [
        {
          tag: 'h2',
          text: 'Célunk, hogy partnereinket mentesítsük a terhektől és megkönnyítsük az engedélyeztetési folyamatot.'
        },
        {
          tag: 'ul',
          header: 'Cégünk az alábbi engedélyek teljeskörű ügyintézésében segít partnereinek:',
          li: [
            'Társadalombiztosítási azonosító jel: Az engedélyeztetési folyamat során biztosítjuk, hogy partnereink munkavállalói ' +
            'rendelkezzenek a társadalombiztosítási azonosító jellel. Ennek az a célja, hogy a munkavállalók jogosultak legyenek a magyarországi ' +
            'egészségügyi szolgáltatásokra és ellátásokra.',
            'Adóazonosító jel: Az adóazonosító jel beszerzése is a mi feladatunk. Ezzel biztosítjuk, hogy a külföldi munkavállalók ' +
            'legálisan dolgozzanak Magyarországon, és az adózás szempontjából is rendelkezzenek minden szükséges dokumentummal.',
            'Tartózkodási engedély: Cégünk segít a tartózkodási engedély megszerzésében is, amely szintén fontos dokumentum a munkavállalás szempontjából. ' +
            'Biztosítjuk, hogy a munkavállalók rendelkezzenek a szükséges tartózkodási engedéllyel, hogy legálisan és zavartalanul dolgozhassanak Magyarországon.'
          ]
        },
        {
          tag: 'p',
          text: 'Tapasztalt csapatunknak köszönhetően garantáljuk a lehető leggyorsabb ügyintézést, így partnereinknek ' +
            'nem kell aggódniuk a hosszadalmas és bonyolult papírmunka miatt. '
        },
        {
          tag: 'p',
          text: 'Bízzon bennünk, hogy teljeskörű segítséget nyújtunk Önnek és vállalkozásának a külföldi munkavállalók ' +
            'magyarországi munkavállalásának engedélyeztetési folyamatában.'
        }
      ]
    }
  },
  {
    id: 2,
    title: 'Toborzás és kiválasztás',
    description: 'Tapasztalt toborzási szakemberek a segítségedre',
    path: 'recruitment-and-selection',
    image: recruitmentWebP,
    imageMin: recruitmentWebP,
    content: {
      coverImage: recruitmentWebP,
      h1: 'Toborzás és kiválasztás',
      subtitle: 'Tapasztalt toborzási szakemberek a segítségedre',
      body: [
        {
          tag: 'h2',
          text: 'Célunk, hogy Önnek a legjobb munkavállalókat ajánljuk fel, akik megfelelnek az Ön vállalkozásának egyedi igényeinek.'
        },
        {
          tag: 'p',
          text: 'A toborzási folyamat során együttműködünk Önnel, hogy a legmegfelelőbb jelölteket találjuk meg az állásleírásának megfelelően. ' +
            'A megbízóval együtt dolgozva egyedi kondíciókat dolgozunk ki, amelyek megfelelnek az Ön vállalkozásának. ' +
            'Ez biztosítja, hogy csak azokat az embereket ajánljuk fel, akik valóban megfelelnek az Ön igényeinek.'
        },
        {
          tag: 'p',
          text: 'Az online interjú szervezése során Ön kérdéseket állíthat össze, amelyeket a jelöltnek meg kell válaszolnia. ' +
            'Ez a folyamat segít abban, hogy jobban megismerjük a jelölteket, és így olyan munkavállalókat találjunk meg, akik tökéletesen illeszkednek a pozícióra.'
        },
        {
          tag: 'p',
          text: 'Bízzon bennünk, hogy megtaláljuk a legmegfelelőbb munkavállalókat Önnek, hogy vállalkozása tovább fejlődhessen.'
        }
      ]
    }
  },
  {
    id: 3,
    title: 'Outsourcing',
    description: 'Az outsourcing és bérmunka szolgáltatások hatékonyabbá teszik az üzleti folyamatokat',
    path: 'outsourcing',
    image: outsorsingWebP,
    imageMin: outsorsingWebP,
    content: {
      coverImage: outsorsingWebP,
      h1: 'Outsourcing',
      subtitle: 'Az outsourcing és bérmunka szolgáltatások hatékonyabbá teszik az üzleti folyamatokat',
      body: [
        {
          tag: 'h2',
          text: 'Az outsourcing az egyik legjobb módja annak, hogy partnereink teljes fókuszálást és erőforrást biztosíthassanak ' +
            'a fő üzleti tevékenységükre, miközben növelik hatékonyságukat és csökkentik költségeiket.'
        },
        {
          tag: 'p',
          text: 'Az Ravien Job Munkaerőkölcsönző Kft. minden élő munkaerőt feltételező munkafolyamat tervezésében és ' +
            'elvégzésében is segítséget nyújt partnereinek.'
        },
        {
          tag: 'ul',
          header: 'Külső szakértői csapatunk a következő szolgáltatásokat kínálja:',
          li: [
            'Adminisztrációs feladatok ellátása (recepciós)',
            'Raktári feladatok ellátása (csomagolás, csomagszortírozás, anyagmozgatás, targoncás munkavégzés)',
            'Takarítás (szobalány)',
            'Betanított munkafolyamatok teljes átvétele',
            'Konyhai kisegítés (reggeliztetés, mosogatás, szakács munkák)'
          ]
        },
        {
          tag: 'p',
          text: 'Az outsourcing használatának jelentős könyveléstechnikai előnyei is vannak a saját állományban való ' +
            'foglalkoztatással szemben, beleértve a bérek, szabadságolások, táppénzek, egészségügyi vizsgálatok és munkavállalói biztosítások terheit.'
        },
        {
          tag: 'ul',
          header: 'Az elsődleges célunk egy stabil és megfelelően dolgozó állomány szervezése, ' +
            'a toborzott munkavállalók menedzselése. Ennek érdekében csapatunk az alábbi szolgáltatásokat is nyújtja:',
          li: [
            'Munkavállalók betanításának és beilleszkedésének segítése.',
            'Tűz- és balesetvédelmi oktatások fordítása és tolmácsolása.',
            'Megbízó által elvárt munkaköri leírások fordítása.'
          ]
        },
        {
          tag: 'p',
          text: 'Ha partnereinknek olyan problémái vannak, amelyek megoldásában szakértelmünkre van szükség, ' +
            'akkor bátran forduljanak hozzánk. Az Ravien Job Munkaerőkölcsönző Kft. szakértői állnak rendelkezésre, hogy megtalálják a megfelelő megoldásokat a problémákra.'
        }
      ]
    }
  }
]
