<template>
  <div class="flex flex-wrap justify-center">
    <div v-for="partner in partners"
         :key="partner"
         class="flex w-1/2 md:w-1/3 lg:w-1/4">
      <img class="m-auto w-1/2" :alt="partner.name" :src="partner.svg">
    </div>
  </div>
</template>

<script>
import hopiSVG from '@/assets/partners/partners-1-hopi.svg'
import padthaiSVG from '@/assets/partners/partners-2-padthai.svg'
import kifliSVG from '@/assets/partners/partners-3-kifli.svg'
import lapkerSVG from '@/assets/partners/partners-4-lapker.svg'
import jegtradeSVG from '@/assets/partners/partners-5-jegtrade.svg'
import ravienSVG from '@/assets/partners/partners-6-ravien.svg'
import sprinterSVG from '@/assets/partners/partners-7-sprinter.svg'
import glsSVG from '@/assets/partners/partners-8-gls.svg'
import kempinskiSVG from '@/assets/partners/partners-9-kempinski.svg'
import mobilboxSVG from '@/assets/partners/partners-10-mobilbox.svg'
import taewoongSVG from '@/assets/partners/partners-11-taewoong.svg'

export default {
  name: 'PartnersList',

  data () {
    const partners = [
      {
        name: 'Hopi',
        svg: hopiSVG
      },
      {
        name: 'Padthai',
        svg: padthaiSVG
      },
      {
        name: 'Kifli',
        svg: kifliSVG
      },
      {
        name: 'Lapker',
        svg: lapkerSVG
      },
      {
        name: 'JetTrade',
        svg: jegtradeSVG
      },
      {
        name: 'Ravien',
        svg: ravienSVG
      },
      {
        name: 'Sprinter',
        svg: sprinterSVG
      },
      {
        name: 'GLS',
        svg: glsSVG
      },
      {
        name: 'Kempinski',
        svg: kempinskiSVG
      },
      {
        name: 'Mobilbox',
        svg: mobilboxSVG
      },
      {
        name: 'Taewoong',
        svg: taewoongSVG
      }
    ]

    return {
      partners
    }
  }
}
</script>
